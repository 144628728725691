import ReactGA from 'react-ga'
import { TLocation } from './types'

declare global {
  interface Window {
    google_conversion_id: number | null
    google_conversion_language: string
    google_conversion_format: string
    google_conversion_color: string
    google_conversion_label: string
    google_remarketing_only: boolean
  }
}

export function initializeUniversalAnalytics(
  themeId: string,
  location: TLocation
) {
  ReactGA.initialize(themeId, {
    gaOptions: {
      allowLinker: true
    }
  })
  ReactGA.pageview(location.pathname + location.search)
}

export function ugaPageView(location: TLocation) {
  ReactGA.pageview(location.pathname + location.search)
}

export function googleConversionData(
  themeId: string,
  themeAdsId: number,
  themeAdsLabel: string,
  orderId: number,
  total: number,
  totalShipping: number,
  planSelectedDetailsSlug: string,
  planSelectedDetailsName: string
) {
  window.google_conversion_id = themeAdsId
  window.google_conversion_language = 'en'
  window.google_conversion_format = '3'
  window.google_conversion_color = 'ffffff'
  window.google_conversion_label = themeAdsLabel
  window.google_remarketing_only = false

  if (document.getElementById('conversion_ga') === null) {
    const script = document.createElement('script')
    script.src = '//www.googleadservices.com/pagead/conversion.js'
    script.async = true
    script.id = 'conversion_ga'
    document.body.appendChild(script)
  }

  if (themeId) {
    ReactGA.initialize(themeId, {
      debug: true,
      gaOptions: {
        allowLinker: true
      }
    })
  }
  ReactGA.plugin.require('linker')

  ReactGA.plugin.execute('linker', 'autoLink', [
    'http://www.leiturinha.com.br',
    'www.leiturinha.com.br',
    'leiturinha.com.br'
  ])

  ReactGA.plugin.require('ecommerce')

  ReactGA.plugin.execute('ecommerce', 'addTransaction', {
    id: '' + orderId,
    affiliation: '',
    revenue: '' + total,
    shipping: '' + totalShipping,
    tax: '0'
  })

  ReactGA.plugin.execute('ecommerce', 'addItem', {
    id: '' + orderId,
    sku: '' + planSelectedDetailsSlug,
    name: planSelectedDetailsName,
    category: 'Literatura Infantil',
    price: '' + total,
    quantity: '1'
  })

  ReactGA.plugin.execute('ecommerce', 'send', {})
  ReactGA.plugin.execute('ecommerce', 'clear', {})
}
