import {
  ApplyCouponEventType,
  PaymentErrorEventType,
  PaymentInfoEventType,
  ProvideAddressEventType,
  ProvideKidsDataEventType,
  SelectContentKidsEventType,
  SelectContentLoginEventType,
  SelectReaderKidsEventType,
  SignUpEventType,
  TChoosePlanPayload,
  TLocation,
  TPurchasePayload,
  TSelectItemPayload,
  ViewContentKidsEventType
} from './types'
import ReactGA from 'react-ga4'

export function ga4PageView(location: TLocation) {
  window.dataLayer.push({
    event: 'page_view',
    page: location.pathname + location.search
  })
}

export function ga4ConversionData(
  themeId: string,
  themeAds: number,
  themeAdsLabel: string,
  orderId: number | null | undefined,
  total: number | null | undefined,
  totalShipping: number | null | undefined,
  planSelectedDetailsSlug: string | undefined,
  planSelectedDetailsName: string | undefined
) {
  window.google_conversion_id = themeAds
  window.google_conversion_language = 'en'
  window.google_conversion_format = '3'
  window.google_conversion_color = 'ffffff'
  window.google_conversion_label = themeAdsLabel
  window.google_remarketing_only = false

  if (document.getElementById('conversion_ga') === null) {
    const script = document.createElement('script')
    script.src = '//www.googleadservices.com/pagead/conversion.js'
    script.async = true
    script.id = 'conversion_ga'
    document.body.appendChild(script)
  }

  if (themeId) {
    ReactGA.initialize(themeId, {
      gaOptions: {
        allowLinker: true
      }
    })
  }

  ReactGA.event('purchase', {
    id: '' + orderId,
    affiliation: '',
    revenue: '' + total,
    shipping: '' + totalShipping,
    plan_slug: planSelectedDetailsSlug,
    plan_name: planSelectedDetailsName,
    tax: '0'
  })

  ReactGA.event('ecommerce', {
    id: '' + orderId,
    affiliation: '',
    revenue: '' + total,
    shipping: '' + totalShipping,
    plan_slug: planSelectedDetailsSlug,
    plan_name: planSelectedDetailsName,
    tax: '0'
  })
}

export function purchaseEvent(payload: TPurchasePayload) {
  window.dataLayer.push({
    event: 'purchase',
    user_id: payload.userId,
    actionField: payload
  })
}

export function selectItemEvent(payload: TSelectItemPayload) {
  window.dataLayer.push({
    event: 'select_item',
    item: payload
  })
}

export function choosePlanEvent(payload: TChoosePlanPayload) {
  window.dataLayer.push({
    event: 'choose_plan',
    content: payload
  })
}

export function viewContentKidsEvent(payload: ViewContentKidsEventType) {
  window.dataLayer.push({
    event: 'view_content',
    content: payload
  })
}

export function selectContentKidsEvent(payload: SelectContentKidsEventType) {
  window.dataLayer.push({
    event: 'select_content',
    content: payload
  })
}

export function selectReaderKidsEvent(payload: SelectReaderKidsEventType) {
  window.dataLayer.push({
    event: 'select_reader_category',
    content: payload
  })
}

export function provideChildDataEvent(payload: ProvideKidsDataEventType) {
  window.dataLayer.push({
    event: 'provide_child_data',
    content: payload
  })
}

export function beginCheckoutEvent(payload: TPurchasePayload) {
  window.dataLayer.push({
    event: 'begin_checkout',
    user_id: payload.userId,
    actionField: payload
  })
}

export function signUpEvent(payload: SignUpEventType) {
  window.dataLayer.push({
    event: 'signup',
    user_id: payload.userId,
    signup: payload
  })
}

export function applyCouponEvent(payload: ApplyCouponEventType) {
  window.dataLayer.push({
    event: 'apply_coupon',
    user_id: payload.userId,
    coupon: payload
  })
}

export function provideAddressDataEvent(payload: ProvideAddressEventType) {
  window.dataLayer.push({
    event: 'provide_address_data',
    user_id: payload.userId,
    address: payload
  })
}

export function paymentInfoEvent(payload: PaymentInfoEventType) {
  window.dataLayer.push({
    event: 'payment_info',
    payment: payload,
    user_id: payload.userId
  })
}

export function paymentErrorEvent(payload: PaymentErrorEventType) {
  window.dataLayer.push({
    event: 'payment_error',
    payment: payload,
    user_id: payload.userId
  })
}

export function selectContentLoginEvent(payload: SelectContentLoginEventType) {
  window.dataLayer.push({
    event: 'select_content',
    content: payload,
    user_id: payload.userId
  })
}
